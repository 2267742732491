














































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import {
  UserStore,
  DemandSupplyStore,
  JWTStore,
  MetaStore,
  CropStore,
  CropVarietyStore
} from "@/store/modules";
import { CompanyUser } from "@/store/models/user";
import { eventHub } from "@/utils/eventHub";
import { MetaInfo } from "vue-meta";

@Component({
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("approvedCompanyList")}`,
      meta: [
        { name: "keyword", content: "home,page" },
        {
          name: "description",
          content: "Approved Companies of SQCC App",
          vmid: "homeScreen"
        }
      ]
    };
  }
})
export default class SeedCompanyList extends Vue {
  pageNum: number = 1;
  user: CompanyUser = {} as CompanyUser;
  searchInput: string = "";
  cropId: number | null = null;
  cropVarietyId: number | null = null;
  seedTypeId: number | null = null;
  districtId: number | null = null;
  supplierName: string | null = null;
  role: string | null = null;
  get roles() {
    return [
      {
        value: "source_seed_producer",
        name: `${this.$t("source")} ${this.$t("seed")} ${this.$t("producer")}`
      },
      {
        value: "improved_seed_producer",
        name: `${this.$t("improved")} ${this.$t("seed")} ${this.$t("producer")}`
      },
      {
        value: "improved_seed_distributor",
        name: `${this.$t("improved")} ${this.$t("seed")} ${this.$t("distributor")}`
      },
      {
        value: "improved_seed_consumer",
        name: `${this.$t("improved")} ${this.$t("seed")} ${this.$t("consumer")}`
      }
    ];
  }

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return i;
  }

  get dataList() {
    return UserStore.AllApprovedUsers;
  }

  private get seedClass() {
    return DemandSupplyStore.SeedClassList;
  }

  get districts() {
    return MetaStore.District;
  }

  get crops() {
    return CropStore.CropNameList;
  }

  get cropVarieties() {
    let varieties = CropVarietyStore.CropVarieties;
    if (this.cropId && this.cropId !== null) {
      varieties = varieties.filter(x => x.croptypeid == this.cropId);
    }
    return varieties;
  }

  get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  normal_case(value: string) {
    if (value) {
      let splittedValue = value.split("_");
      let translatedValue = [];
      for (let value of splittedValue) {
        translatedValue.push(this.$t(value).toString());
      }
      value = translatedValue.join(" ");
    }
    return value;
  }

  changeCrop() {
    this.cropVarietyId = null;
  }

  handlePrevNextClick(val: any, list: string) {
    this.pageNum = val;
    this.$emit("handlePageChange", val, list);
  }

  filterHandler(value: any, row: any, column: any) {
    const property = column["property"];
    return row[property] === value;
  }

  search() {
    let query: any = { page: 1 };
    if (this.cropId != null) {
      query["cropId"] = String(this.cropId);
    }
    if (this.cropVarietyId != null) {
      query["cropVarietyId"] = String(this.cropVarietyId);
    }
    if (this.seedTypeId != null) {
      query["seedTypeId"] = String(this.seedTypeId);
    }
    if (this.districtId != null) {
      query["districtId"] = String(this.districtId);
    }
    if (this.supplierName != null) {
      query["searchString"] = String(this.supplierName);
    }
    if (this.role != null) {
      query["role"] = String(this.role);
    }
    this.routerReplacer();

    UserStore.getAllApprovedUserList(query);
  }

  clearSearch() {
    let query: any = { page: 1 };
    this.cropId = null;
    this.cropVarietyId = null;
    this.seedTypeId = null;
    this.districtId = null;
    this.supplierName = null;
    this.role = null;
    this.routerReplacer();

    UserStore.getAllApprovedUserList(query);
  }

  handlePageChange(val: any) {
    this.pageNum = val;
    this.routerReplacer();

    UserStore.getAllApprovedUserList({ page: val });
  }

  routerReplacer() {
    let query: any = {};
    if (this.pageNum) {
      query["page"] = String(this.pageNum);
    }
    if (this.cropId != null) {
      query["cropId"] = String(this.cropId);
    }
    if (this.cropVarietyId != null) {
      query["cropVarietyId"] = String(this.cropVarietyId);
    }
    if (this.seedTypeId != null) {
      query["seedTypeId"] = String(this.seedTypeId);
    }
    if (this.districtId != null) {
      query["districtId"] = String(this.districtId);
    }
    if (this.supplierName != null) {
      query["searchString"] = String(this.supplierName);
    }
    if (this.role != null) {
      query["role"] = String(this.role);
    }
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: query
        })
        .catch(error => {});
    }
    return query;
  }

  created() {
    MetaStore.getAllDistrict();
    // CropStore.getAllCropName();
    // CropVarietyStore.getAllCropVariety();
    // DemandSupplyStore.getAllSeedType();

    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }

    if (this.$route.query.cropId) {
      this.cropId = Number(this.$route.query.cropId);
    }

    if (this.$route.query.seedTypeId) {
      this.seedTypeId = Number(this.$route.query.seedTypeId);
    }
    if (this.$route.query.supplierName) {
      this.supplierName = String(this.$route.query.supplierName);
    }
    if (this.$route.query.role) {
      this.role = String(this.$route.query.role);
    }
    let query = this.routerReplacer();

    UserStore.getAllApprovedUserList(query);
  }
}
